import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { en } from "./locales";
import XHR from "i18next-xhr-backend";
import { HelmetProvider } from "react-helmet-async";
import { AuthProvider } from "./context/authContext";
import { ConfigProvider } from "antd";
import { GoogleOAuthProvider } from "@react-oauth/google";

const options = {
  order: ["querystring", "navigator"],
  lookupQuerystring: "lng",
};
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(XHR)
  .init({
    resources: { en },
    defaultNS: "common",
    supportedLngs: ["en"],
    ns: ["common"],
    fallbackLng: "en",
    interpolation: { escapeValue: false },
    detection: options,
  });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <AuthProvider>
        <GoogleOAuthProvider clientId="506419402946-tkk8pjij6lsok9tq5km4kgu8cmlapp5n.apps.googleusercontent.com">
          <BrowserRouter>
            <ConfigProvider>
              <App />
            </ConfigProvider>
          </BrowserRouter>
        </GoogleOAuthProvider>
      </AuthProvider>
    </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
