import parse from "emailjs-mime-parser";
import MsgReader from "@kenjiuno/msgreader";

const readFile = (fileData, readType) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (e) => {
            resolve(e.target.result);
        };

        reader.onerror = (error) => {
            reject(error);
        };
        if (readType === 'text') reader.readAsText(fileData);
        else reader.readAsArrayBuffer(fileData);
    });
};

export const getAttachmentsFromEml = async (file) => {
    const eml = await readFile(file, 'text')
    const parsed = parse(eml);
    const attachments = []
    parsed.childNodes?.forEach(node => {
        if (node.headers['content-disposition']?.[0]) {
            const filename = node.headers['content-disposition'][0].params.filename
            if (filename.split('.').pop().toLowerCase() !== 'pdf') return
            const blob = new Blob([node.content], {type: node.contentType.value});
            const f = new File([blob], filename, {type: node.contentType.value});
            attachments.push(f)
        }
    })
    return attachments
}

export const getAttachmentsFromMsg = async (file) => {
    const msg = await readFile(file, 'arrayBuffer')
    const parsed = new MsgReader(msg)
    const msgData = parsed.getFileData()
    const attachments = []

    msgData.attachments.forEach(attachment => {
        if (attachment.fileName.split('.').pop().toLowerCase() !== 'pdf') return
        const blob = new Blob([parsed.getAttachment(attachment).content],
            {type: attachment.attachMimeTag});
        const f = new File([blob], attachment.fileName, {type: attachment.attachMimeTag});
        attachments.push(f)
    })
    return attachments
}
